import React from 'react'
import { Link } from 'react-router-dom'
import Layout from '../../complex/Layout/Layout'
import styles from './Contats.module.scss'
import inst from '../../../assets/icon/instagram.svg'
import telegram from '../../../assets/icon/telegram.svg'
import vk from '../../../assets/icon/vk.svg'
import Location from '../../../assets/icon/location.svg'
import phone from '../../../assets/icon/phone.svg'

type Props = {}
const text = 'Забронируйте маршрут и время для своего отдыха: по телефону, электронной почте, через соцсети и мессенджеры. Мы поможем с выбором маршрута и ответим на ваши вопросы.'
const Contats = (props: Props) => {
    return (
        <Layout column={text}>
            <section className={styles.contat}>
                <div className={styles.map}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2287.815420858207!2d21.2402773!3d55.0114005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e37fd670d0493f%3A0x36b51e09bf3e4b22!2z0J_Qu9C-0YIg0JzQsNGC0YDQvtGB0LrQuNC9!5e0!3m2!1sru!2sru!4v1656653910688!5m2!1sru!2sru" style={{ border: 0, width: "100%", height: "100%" }} loading="lazy" />

                </div>
                <div className={styles.bottomWrapperItem}>
                    <div className={styles.bottomItem}>
                        <h3>Мы в социальных сетях</h3>
                        <div>
                            <div className={styles.social}>
                                <ul>
                                    {/* <li><Link to='/'> <img src={inst} /></Link></li> */}
                                    <li><Link to='/'> <img src={telegram} /></Link></li>
                                    <li><Link to='/'> <img src={vk} /></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={styles.bottomItem}>
                        <h3>Контакты</h3>
                        <div className={styles.phone}>
                            <img src={phone} /> <span>+7 (906) 237-70-77</span>
                        </div>
                    </div>
                    <div className={styles.bottomItem}>
                        <h3>Адрес</h3>
                        <div className={styles.phone}>
                            <img src={Location} /> <span>Калининградская обл., пос. Матросово,  Левобережная 16</span>
                        </div>
                    </div>
                    {/* <div className={styles.bottomItem}>
                        <h3>Наши партнёры</h3>
                    </div> */}
                </div>
            </section>
        </Layout>
    )
}

export default Contats