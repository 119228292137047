import classNames from 'classnames'
import {
    FC,
    SyntheticEvent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import styles from './Dropdown.module.scss'
import { ReactComponent as Mark } from '../../../assets/icon/Drop.svg'
import Item from './Item'
import { EActive } from '../Input/Input'



export type OptionType = {
    label: string
    value: string
    keyName?: string
    payload?: any
    options?: Array<OptionType>
}
type Props = {
    error?: string
    placeholder?: string
    value: string[]

    keyValue?: {
        value: string
        keyName: string
    }
    options: Array<OptionType>
    onChange: (data: {
        value: string,
        keyName?: string
    }) => void
    disabled?: boolean
    classes?: {
        root?: string
        label?: string
        drop?: string
    }

    icon?: JSX.Element


}


const Dropdown: FC<Props> = ({
    error,
    placeholder,
    value,
    icon,
    options,
    onChange,
    disabled,
    classes,
}) => {
    const [openStatus, setOpenStatus] = useState<boolean>(false)

    const optionsBlock = useRef<HTMLDivElement>(null)
    const mainBlock = useRef<HTMLDivElement>(null)

    const onHandleChange = useCallback(
        (e: {
            value: string
            keyName?: string
        }) => {
            onChange(e)
            setOpenStatus(false)
        },
        [onChange]
    )



    // Метод сброса фокуса с выбранного элемента
    const loseFocus = useCallback(() => {
        if (optionsBlock && optionsBlock.current) {
            optionsBlock.current.blur()
        }
    }, [])

    // Метод закрытия селектора при клике вне самого селектора
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const closeSelectOutOfBlock = (event: any) => {
        if (mainBlock && mainBlock.current) {
            // Проверка добавлена для устранения бага в Firefox
            if (!mainBlock.current.contains(event.target)) {
                setOpenStatus(false)
                loseFocus()
            }
        }
    }
    // Установка/удаление обработчика события на документе.
    useEffect(() => {
        document.addEventListener('click', closeSelectOutOfBlock, false)
        return () => {
            document.removeEventListener('click', closeSelectOutOfBlock, false)
        }
    }, [closeSelectOutOfBlock])




    const initLabel = useMemo(() => {
        let title: Array<string> = []
        options.map((i) => {
            if (value.includes(i.value)) {
                title.push(i.label)
            }
        })        
        let t = title?.join(', ') || placeholder
        return t
    }, [options, placeholder, value])

    const onHandleOpenoptions = (e: SyntheticEvent<HTMLDivElement>) => {
        if (disabled) return
        e.stopPropagation();
        setOpenStatus((prev) => !prev)
    }
    const [active, setActive] = useState<EActive>(EActive.BLUR)

    useEffect(() => {
        if (openStatus) {
            setActive(EActive.FOCUS)
        } else {
            setActive(EActive.BLUR)

        }
    }, [openStatus])

    return (
        <>
            <div
                ref={mainBlock}
                className={classNames(styles.select, classes?.root, {
                    [styles.disabled]: disabled,
                    [styles.error]: !!error,
                    [styles.blur]: active === EActive.BLUR,
                    [styles.focus]: active === EActive.FOCUS,

                })}
                onClick={onHandleOpenoptions}
            >
                <div className={styles.topLabel}>
                    <div className={classNames(styles.value, {
                        [styles.placeholder]: placeholder === initLabel
                    })}>{initLabel}</div>


                    {icon ?
                        <div className={classNames(styles.mark, {
                            [styles.activeMark]: openStatus,
                        })}>{icon}</div> :
                        <Mark
                            className={classNames(styles.mark, {
                                [styles.activeMark]: openStatus && !disabled,
                            })}
                        />}



                </div>
                {openStatus && !disabled && (

                    <div
                        ref={optionsBlock}
                        className={classNames(styles.drop, classes?.drop)}
                    >
                        {
                            options.map((i) => (
                                <Item
                                    key={i.value}
                                    onClick={onHandleChange}
                                    option={i}
                                />
                            ))
                        }
                    </div>
                )}
            </div>
            {error && <div className={styles.errorText}>{error}</div>}
        </>
    )
}

export default Dropdown
