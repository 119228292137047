import React, {FC, useContext, useEffect, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {RouteSectionType} from '../../../../Types/Types'
import Layout from '../../../complex/Layout/Layout'
import Section from '../../../simple/Section/Section'
import {GalleryContext} from '../GalleryContext'
import styles from './GallertItems.module.scss'
import {GallerySection} from "../Gallery";
import {collection, getDocs} from "firebase/firestore";
import {db} from "../../../../utilits/firebaseConfig";

type Props = {}

const GalleryItem = (props: Props) => {
    const params = useParams()
    const [titleRight, setTitleRight] = useState<GallerySection[]>([])
    console.log(titleRight);


    const [items, setItems] = useState<string[]>()
    const Route: RouteSectionType[] = titleRight.map(item => {
        return {
            label: item.title,
            rout: 'gallery/galleryitems/' + item.id
        }
    })

    const SectMain: FC = (): JSX.Element => {
        return (
            <Section label="Разделы:" sections={Route}/>
        )
    }
    const galleryColl = collection(db, 'gallery')

    const getGallery = async () => {
        const data = await getDocs(galleryColl)
        setTitleRight(data.docs.map((item: any) => ({...item.data(), id: item.id})))
    }

    useEffect(() => {

        getGallery()

    }, [])

    return (
        <Layout mainSection={<SectMain/>}>
            <section className={styles.sectionGallery}>
                {titleRight.find(item => item.id === params.idGallery)?.imgItems?.map(item => <div className={styles.galleryItems}><img src={item}/></div>)}
            </section>
        </Layout>
    )
}

export default GalleryItem
