import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout } from '../../layout'
import Button from '../../simple/Button/Button'
import styles from './NotFound.module.scss'
type Props = {}

const NotFound = (props: Props) => {
    const navigate = useNavigate()
    return (
        <section className={styles.notFound}>
            <div className={styles.title}>
                <h1>404 PAGE</h1>
                <h3>Извините, но мы не нашли такую страницу :(</h3>
                <Button onClick={() => navigate('/')} className={styles.button}>Вернуться на главную</Button>
            </div>
        </section>
    )
}

export default NotFound