import { FC } from 'react'
import { RouteSectionType } from '../../../Types/Types'
import Section from '../../simple/Section/Section'
import { Outlet } from 'react-router-dom'
type Props = {}


const Servives = (props: Props) => {
  return (
   <Outlet />
  )
}

export default Servives

