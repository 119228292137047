import Button from '../../../../simple/Button/Button';
import campImgOne from '../../../../../assets/image/campingOne.jpg';
import campImgTwo from '../../../../../assets/image/campingTwo.jpg';
import styles from './Camping.module.scss';
type Props = {}








const Camping = (props: Props) => {




    return (
        <section className={styles.camping}>
            <div className={styles.campingTitle}>
                <div className={styles.container}>
                    <h1 className={styles.titleSection}>
                        Кемпинг “Плот Матроскин”
                    </h1>
                    <p className={styles.paragraphSection}>
                        На противоположном берегу реки от базы «Плот Матроскин» не ходят машины, нет дорог и шума. Добраться можно только вплавь — идеальное место для отдыха от суеты и городской пыли.
                    </p>
                </div>
            </div>
            <div className={styles.subscription}>
                <div className={styles.container}>
                    <div className={styles.wrapper}>
                        <div className={styles.title}>
                            <h3>Платите <span className={styles.blue}>один раз</span>  и получаете комплект продуманного туриста <span className={styles.blue}>на сутки</span>: палатка, спальный мешок, лодка.</h3>
                        </div>
                        <div className={styles.img}>
                            <img src={campImgOne} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.suitableCamping}>
                    <div className={styles.suitableCampingImg}>
                        <img src={campImgTwo} />
                    </div>
                    <div className={styles.suitableCampingList} >
                        <h4 className={styles.suitableCampingTitle}>Для чего подойдёт кемпинг «Плот Матроскин»</h4>
                        <ul className={styles.suitableCampingList}>
                            <li className={styles.li}>Организовать дружеский слёт</li>
                            <li className={styles.li}>Отдохнуть в тишине и вдали от цивилизации
                            </li>
                            <li className={styles.li}>Отпраздновать важную дату в необычной обстановке
                            </li>
                            <li className={styles.li}>Устроить день с самим собой и уединиться с мыслями
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={styles.campingFooter}>
                <div className={styles.container}>
                    <h1 className={styles.titleSectionFooter}>
                        Уже подготовили для вас двухместную палатку, свернули два спальных мешка и помыли лодку, когда вас ждать?
                    </h1>
                    <p className={styles.paragraphSection}>
                        Чтобы забронировать свой день тишины, звоните по телефону <span className={styles.bold}> <a href='tel:+79062377077'> +7 (906) 237-70-77</a></span>

                    </p>
                </div>
            </div>
        </section>
    )
}

export default Camping