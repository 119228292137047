import React, { FC } from 'react'
import { RouteSectionType } from '../../../../../Types/Types'
import Layout from '../../../../complex/Layout/Layout'
import Section from '../../../../simple/Section/Section'
import styles from './MainPage.module.scss'
import rervicesExucursion from '../../../../../assets/image/rervicesExucursion.svg'
import rervicesOrders from '../../../../../assets/image/rervicesOrders.svg'
import rervicesRules from '../../../../../assets/image/rervicesRules.svg'
import rerviceMenu from '../../../../../assets/image/rerviceMenu.jpeg'
import rervicesTravel from '../../../../../assets/image/rervicesTravel.svg'
import rervicesCamping from '../../../../../assets/image/rervicesCamping.svg'
import { Link } from 'react-router-dom'
type Props = {}

const Route: RouteSectionType[] = [
  {
    label: 'Экскурсии',
    rout: 'services/excursion'
  },
  {
    label: 'Путешествия',
    rout: 'services/travel'
  },
  {
    label: 'Меню',
    rout: 'services/menu'
  },
  {
    label: 'Местные товары с пасеки',
    rout: 'services/orders'
  },
  {
    label: 'Правила посещения',
    rout: 'services/rule'
  },
  {
    label: 'Кемпинг',
    rout: 'services/camping'
  },
]
export const SectMain: FC = (): JSX.Element => {
  return (
    <Section label="Разделы:" sections={Route} />
  )
}


const MainPageServices = (props: Props) => {
  return (
    <Layout mainSection={<SectMain />}>
      <section className={styles.services}>
        <div className={styles.itemWrapper}>
          <div className={styles.item}>
            <Link to='/services/excursion'>
              <img src={rervicesExucursion} />
            </Link>
          </div>
          <div className={styles.item}>
            <Link to='/services/rule'>
              <img src={rervicesRules} />
            </Link>
          </div>
          <div className={styles.item}>
            <Link to='/services/orders'>
              <img src={rervicesOrders} />
            </Link>
          </div>
          <div className={styles.item}>
            <Link to='/services/travel'>
              <img src={rervicesTravel} />
            </Link>
          </div>
          <div className={styles.item}>
            <Link to='/services/camping'>
              <img src={rervicesCamping} />
            </Link>
          </div>
          <div className={styles.item}>
            <Link to='/services/menu'>
              <img src={rerviceMenu} />
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default MainPageServices

