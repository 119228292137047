import React, {FC, useEffect, useState} from 'react'
import {CalendarSaveType, dateSaveType, RouteSectionType} from '../../../../../Types/Types'
import Layout from '../../../../complex/Layout/Layout'
import Button from '../../../../simple/Button/Button'
import Section from '../../../../simple/Section/Section'
import {SectMain} from '../MainPage/MainPageServices'
import styles from './Menu.module.scss'
import img from '../../../../../assets/image/travelImg.jpg'
import {collection, getDocs} from 'firebase/firestore'
import {db} from '../../../../../utilits/firebaseConfig'
import {async} from '@firebase/util'
import TravelItem from "../Travel/parts/TravelItem";
import MenuItem, {MenuType} from "./parts/MenuItem";
import breakfast1 from '../../../../../assets/image/menu/breakfast1.jpg'
import breakfast2 from '../../../../../assets/image/menu/breakfast2.jpg'
import breakfast3 from '../../../../../assets/image/menu/breakfast3.jpg'
import lunch1 from '../../../../../assets/image/menu/lunch1.jpg'
import lunch2 from '../../../../../assets/image/menu/lunch2.jpg'
import lunch3 from '../../../../../assets/image/menu/lunch3.jpg'
import lunch4 from '../../../../../assets/image/menu/lunch4.jpeg'
import lunch5 from '../../../../../assets/image/menu/lunch5.jpeg'

type Props = {}
const menuList: MenuType[] = [{
    title: 'Завтрак №1',
    cost: "350 руб. с человека.",
    description: 'Сэндвичи с колбасой и сыром, фруктовая тарелка, сладкая тарелка, четыре вида орешков, чай, кофе.',
    iconSrc: breakfast1
}, {
    title: 'Завтрак №2',
    description: 'Сырники из фермерского творога с домашней сметаной и вареньем, чай, кофе.',
    iconSrc: breakfast2,
    cost: '400 руб. с человека.'
}, {
    title: 'Завтрак №3',
    description: "Яичница из домашних яиц, овощная нарезка, хлебная корзина, чай, кофе.",
    iconSrc: breakfast3,
    cost: '400 руб. с человека.'
}, {
    title: 'Обед №1',
    description: 'Жареное филе судака с рисом и овощным салатом.', cost: '700 руб. с человека.', iconSrc: lunch1
}, {
    title: 'Обед №2',
    description: 'Хрустящая балтийская корюшка с рисом и овощным салатом.',
    cost: '700 руб. с человека.',
    iconSrc: lunch2
}, {
    title: 'Обед №3',
    description: 'Филе балтийского лосося со спаржей и сливочным соусом.',
    cost: '1100 руб. с человека.',
    iconSrc: lunch3
}, {
    title: 'Обед №4',
    description: 'Креветки по-мексикански с чесночным соусом на оливковом масле.',
    cost: '1000 руб. с человека.',
    iconSrc: lunch4
}, {
    title: 'Обед №5',
    description: 'Макароны по-флотски из фермерской тушёнки с овощным салатом.',
    cost: '500 руб. с человека.',
    iconSrc: lunch5
},]


export type TravelType = {
    description: string
    advantages: string
    cost: string
    food: string
    id: string
    img: string
    length: string
    title: string
    time: string
    dateSave: dateSaveType[]
    saveDateInCalendar: CalendarSaveType[]
}


const Menu = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [travel, setTravel] = useState<TravelType[]>([])
    const travelColl = collection(db, 'travel')
    const getTravel = async () => {
        setLoading(false)
        const data = await getDocs(travelColl)
        setTravel(data.docs.map((item: any) => ({...item.data(), id: item.id})))
        setLoading(true)
    }

    useEffect(() => {
        getTravel()
    }, [])
    const SectSub: FC = (): JSX.Element => {
        return (<Section label="Путешествия:"
                         sections={travel.map(item => ({label: item.title, rout: 'services/travel'}))}/>)
    }
    return (<Layout mainSection={<SectMain/>} className={styles.layoutExcursion} subSection={<SectSub/>}>
        <section className={styles.travel}>
            <div className={styles.title}>
                <h3 className={styles.importansDetalis}>Важные детали!</h3>
                <p>В маршрутах, где включён завтрак и обед, гости выбирают блюда по меню заранее , чтобы наш капитан
                    успел приобрести нужные продукты и доставить их на плот.</p>
                <p>Дополнительные блюда также обговариваются заранее и оплачиваются отдельно.</p>
            </div>
            <div className={styles.cardWrapper}>
                {loading && menuList.map((item: MenuType) => <MenuItem data={item}/>)}
            </div>
        </section>
    </Layout>)
}

export default Menu
