import React, {FC, useState} from 'react'
import ReserveRouteForms from '../../../../../complex/reserveRouteForms/ReserveRouteForms'
import Button from '../../../../../simple/Button/Button'
import {array1, array2} from '../../../../MainPage/parts/Top/Top'
import styles from '../Menu.module.scss'

export  interface MenuType {
    iconSrc: string
    title: string
    description: string
    cost: string
}

type Props = {
    data: MenuType
}

const MenuItem: FC<Props> = ({data}) => {


    const [reserve, setReserve] = useState<boolean>(false)
    const reserveRoute = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        e.preventDefault()
        setReserve(true)
    }


    return (<>
        <div className={styles.card}>
            <div className={styles.img}>
                <img src={data.iconSrc}/>
            </div>
            <div className={styles.right}>
                <div className={styles.itemWrapper}>
                    <h3>{data.title}</h3>
                    <div className={styles.item}>
                        <h5>Описание: </h5>
                        <h6 className={styles.time}>{data.description}</h6>
                    </div>
                    <div className={styles.item}>
                        <h5>Стоимость: </h5>
                        <h6 className={styles.time}>{data.cost}</h6>
                    </div>
                </div>
            </div>
        </div>

    </>)
}

export default MenuItem
