import React, { FC, useState } from 'react'
import ReserveRouteForms from '../../../../../complex/reserveRouteForms/ReserveRouteForms'
import Button from '../../../../../simple/Button/Button'
import { array1, array2 } from '../../../../MainPage/parts/Top/Top'
import { ExcursionType } from '../Excursion'
import styles from '../Excursion.module.scss'
type Props = {
    data: ExcursionType
}

const ExcursionItem: FC<Props> = ({ data }) => {
    const [reserve, setReserve] = useState<boolean>(false)
    const reserveRoute = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        e.preventDefault()
        setReserve(true)
    }
    return (
        <>
            <div className={styles.card}>
                <div className={styles.img}>
                    <img src={data.img} />
                </div>
                <div className={styles.right}>
                    <div className={styles.itemWrapper}>
                        <h3>{data?.title}</h3>
                        <div className={styles.item}>
                            <h5>Длительность:  </h5>
                            <h6>{data.time}</h6>
                        </div>
                        <div className={styles.item}>
                            <h5>Маршрут: </h5>
                            <h6>{data.route} </h6>
                        </div>
                        <div className={styles.item}>
                            <h5>Стоимость:</h5>
                            <h6> {data.cost} </h6>
                        </div>
                        <div className={styles.item}>
                            <h5>Питание:</h5>
                            <h6>{data.food}</h6>
                        </div>
                        <div className={styles.item}>
                            <h5>Описание: </h5>
                            <h6>{data.description}</h6>
                        </div>
                    </div>
                    <div className={styles.button}>
                        <Button onClick={(e) => reserveRoute(e)} >
                            Забронировать
                        </Button>
                    </div>
                </div>
            </div>

            {reserve && <ReserveRouteForms
                dropDownOne={array1}
                dropDownTwo={array2}
                onClose={() => setReserve(false)}
            />}
            </>
    )
}

export default ExcursionItem