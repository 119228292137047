import React from 'react'
import styles from './Contacts.module.scss'
import phone from '../../../../../assets/icon/phoneContacs.svg'
import compass from '../../../../../assets/icon/contactsCompas.svg'
import location from '../../../../../assets/icon/contactsLocation.svg'
type Props = {}

const Contacts = (props: Props) => {
    return (
        <section className={styles.Contacts}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <h1>Контакты</h1>
                    <h3>Для бронирования и по любым вопросам звоните <br /> нам по телефону +7 (906) 237-70-77</h3>
                </div>
                <div className={styles.wrapperItem}>
                    <div className={styles.item}>
                        <div className={styles.img}>
                            <img src={phone} />
                        </div>
                        <h4>Контакты</h4>
                        <h5>+7 (906) 237-70-77</h5>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.img}>
                            <img src={location} />
                        </div>
                        <h4>Адрес</h4>
                        <h5> Калининградская обл., Полесский район, пос. Матросово, ул. Левобережная 16</h5>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.img}>
                            <img src={compass} />
                        </div>
                        <h4>Как добраться</h4>
                        <h5>На машине, автобусе или поезде. Время в пути из Калининграда — около часа.</h5>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contacts