import React, { createContext } from "react";
import { BlogType } from "./Blog";



export const BlogContext = createContext<[
    BlogType[],
    React.Dispatch<React.SetStateAction<BlogType[]>>,
    string | undefined,
    React.Dispatch<React.SetStateAction<string | undefined>>
]>([
    [],
    () => { },
    '',
    () => { }
])