import React, { FC, useState } from 'react'
import ReserveRouteForms from '../../../../../complex/reserveRouteForms/ReserveRouteForms'
import Button from '../../../../../simple/Button/Button'
import { array1, array2 } from '../../../../MainPage/parts/Top/Top'
import { TravelType } from '../Travel'
import styles from '../Travel.module.scss'
type Props = {
    data: TravelType
}

const TravelItem: FC<Props> = ({ data }) => {


    const [reserve, setReserve] = useState<boolean>(false)
    const reserveRoute = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        e.preventDefault()
        setReserve(true)
    }



    return (
        <>
            <div className={styles.card}>
                <div className={styles.img}>
                    <img src={data.img} />
                </div>
                <div className={styles.right}>
                    <div className={styles.itemWrapper}>
                        <h3>{data.title}</h3>
                        <div className={styles.item}>
                            <h5>Длительность:  </h5>
                            <h6>{data.time}</h6>
                        </div>
                        {data.length&&<div className={styles.item}>
                            <h5>Протяжённость: </h5>
                            <h6>{data?.length}</h6>
                        </div>}
                        <div className={styles.item}>
                            <h5>Описание:</h5>
                            <h6> {data.description} </h6>
                        </div>
                        <div className={styles.item}>
                            <h5>Преимущества:</h5>
                            <h6>{data.advantages}</h6>
                        </div>
                        <div className={styles.item}>
                            <h5>Питание:</h5>
                            <h6>{data.food}</h6>
                        </div>
                        <div className={styles.item}>
                            <h5>Стоимость: </h5>
                            <h6 className={styles.time}>{data.cost}</h6>
                        </div>
                    </div>
                    <div className={styles.button}>
                        <Button onClick={(e) => reserveRoute(e)}>
                            Забронировать
                        </Button>
                    </div>
                </div>
            </div>

            {reserve && <ReserveRouteForms
                dropDownOne={array1}
                dropDownTwo={array2}
                onClose={() => setReserve(false)}
            />}</>)
}

export default TravelItem
