import React from 'react';
import { Route, Routes} from 'react-router-dom';
import { Layout } from '../components/layout';
import About from '../components/page/About/About';
import { Admin } from '../components/page/Admin';
import Blog from '../components/page/Blog/Blog';
import Contats from '../components/page/Contats/Contats';
import Gallery from '../components/page/Gallery/Gallery';
import GalleryItem from '../components/page/Gallery/parts/GalleryItem';
import MainPage from '../components/page/MainPage/MainPage';
import NotFound from '../components/page/NotFound/NotFound';
import Rewievs from '../components/page/Rewievs/Rewievs';
import Camping from '../components/page/Servives/parts/Camping/Camping';
import Excursion from '../components/page/Servives/parts/Excursion/Excursion';
import MainPageServices from '../components/page/Servives/parts/MainPage/MainPageServices';
import Orders from '../components/page/Servives/parts/Orders/Orders';
import Rules from '../components/page/Servives/parts/Rules/Rules';
import Travel from '../components/page/Servives/parts/Travel/Travel';
import Servives from '../components/page/Servives/Servives';
import './App.module.scss';
import Menu from "../components/page/Servives/parts/Menu/Menu";

function App() {
  return (
    <div>
      <Layout>
        <Routes>
          <Route path="*" element={<MainPage />} />
          <Route path="/" element={<MainPage />} />
          <Route path="about" element={<About />} />
          <Route path="services" element={<Servives />} >
            <Route index element={<MainPageServices />} />
            <Route path="excursion" element={<Excursion />} />
            <Route path="rule" element={<Rules />} />
            <Route path="orders" element={<Orders />} />
            <Route path="travel" element={<Travel />} />
            <Route path="menu" element={<Menu />} />
            <Route path="camping" element={<Camping />} />
          </Route>
          {/* <Route path="admin" element={<Admin />} /> */}
          <Route path="blog" element={<Blog />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="gallery/galleryitems/:idGallery" element={<GalleryItem />} />
          <Route path="rewievs" element={<Rewievs />} />
          <Route path="contats" element={<Contats />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
