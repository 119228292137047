import React, {FC, KeyboardEventHandler, useCallback, useEffect, useState} from 'react'
import ReactDOM from 'react-dom'
import classNames from "classnames";
import {ReactComponent as Close} from '../../../assets/icons/close.svg'
import styles from './ReserveRouteForms.module.scss'
import {title} from 'process';
import Checkbox from '../../simple/Checkbox/Checkbox';
import Button, {ButtonSizeType} from '../../simple/Button/Button';
import Input from '../../simple/Input/Input';
import Dropdown, {OptionType} from '../../simple/Dropdown/Dropdown';
import produce from 'immer';
import {dateSaveType, FormType} from '../../../Types/Types';
import SuccesReserve from '../../simple/SuccesReserve/SuccesReserve';
import emailjs from '@emailjs/browser';
import {TravelType} from '../../page/Servives/parts/Travel/Travel';
import {ExcursionType} from '../../page/Servives/parts/Excursion/Excursion';
import {collection, doc, getDocs, setDoc} from 'firebase/firestore';
import {db} from '../../../utilits/firebaseConfig';

let container: any;
if (typeof window !== 'undefined') {

    const modalParent = document.createElement('div')
    modalParent.className = styles.modalParent
    document.body.appendChild(modalParent)

    container = modalParent;

}

type Props = {
    onClose?: () => void,

    close?: boolean
    dropDownOne: OptionType[]
    dropDownTwo: OptionType[]
}


const ReserveRouteForms: FC<Props> = ({onClose, dropDownOne, dropDownTwo, close = true}) => {

    const [form, setForm] = useState<FormType>({} as FormType)
    const [check, setCheck] = useState<boolean>(false)

    const [travel, setTravel] = useState<TravelType[]>([])
    const [excursion, setExcursion] = useState<ExcursionType[]>([])
    const [allRoute, setAllRoute] = useState<any>([])
    const [route, setRoute] = useState<OptionType[]>([])
    const [date, setDate] = useState<OptionType[]>([])
    const [selectDateSend, setSelectDateSend] = useState<dateSaveType>()
    const [editDoc, setEditDoc] = useState<any>()
    const [editId, setEditId] = useState<string>('')


    const closeESC = useCallback((event: KeyboardEventHandler<HTMLDivElement> | any): void => {
        if (event.key === 'Escape') {
            if (onClose) {
                onClose()
            }
        }
    }, [onClose])

    // Установка/удаление обработчика события на документе.
    useEffect(() => {
        document.addEventListener('keydown', closeESC, false)
        return () => {
            document.removeEventListener('keydown', closeESC, false)
        }
    }, [closeESC])

    const setValue = (value: string, key: keyof FormType) => {
        setForm(produce(draft => {
            const f: any = draft
            f[key] = value
        }))
    }


    const checkFun = () => {
        setCheck(prev => !prev)
    }
    const testSet = async () => {
        let type = ''
        if (travel.find(item => item.id === editId)) {
            type = 'travel'
        } else {
            type = 'excursion'
        }


        await setDoc(doc(db, type, editDoc.id), {
            ...editDoc, saveDateInCalendar: [...editDoc.saveDateInCalendar, {
                date: selectDateSend?.valueLabel, value: selectDateSend?.valueHours
            }]

        });

    }

    const sendMail = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        if (check && form.number) {
            testSet()

            setCheck(true)

            emailjs.send("service_trgigim", "template_rltt6n9", {
                route: form.route, date: form.date, number: form.number, name: form.name, email: form.email,
            }, 'NjCQ2BpbtE66zmwfU');
            if (onClose) {
                onClose()
            }
        }

    }

    const travelColl = collection(db, 'travel')
    const excursionColl = collection(db, 'excursion')

    const getRoute = async () => {
        const travel = await getDocs(travelColl)
        setTravel(travel.docs.map((doc: any) => ({...doc.data(), id: doc.id})))
        const excursion = await getDocs(excursionColl)
        setExcursion(excursion.docs.map((doc: any) => ({...doc.data(), id: doc.id})))
    }

    useEffect(() => {
        setAllRoute([...excursion, ...travel])
    }, [travel, excursion])
    useEffect(() => {
        setRoute(allRoute.map((item: any) => ({label: item.title, value: `${item.id}${item.title}`})))
    }, [allRoute])

    useEffect(() => {
        getRoute()
    }, [])

    useEffect(() => {
        setValue('', 'date')
        console.log("=>(ReserveRouteForms.tsx:156) allRoute.find((item: any) => `${item.id}${item.title}` === [form.route][0])?", allRoute.find((item: any) => `${item.id}${item.title}` === [form.route][0]));
        console.log(allRoute.find((item: any) => {
            console.log("=>(ReserveRouteForms.tsx:156) `${item.id}${item.title}`", `${item.id}${item.title}`);
            console.log("=>(ReserveRouteForms.tsx:157) [form.route][0]", [form.route][0]);
            return `${item.id}${item.title}` === [form.route][0]
        }))
        const dateSave = allRoute.find((item: any) => `${item.id}${item.title}` === [form.route][0])?.dateSave ?? allRoute.find((item: any) => `${item.id}${item.title}` === [form.route][0])?.dataSave

        setDate(dateSave?.map((item: dateSaveType) => ({
            label: item.label,
            value: `${(item.label + item.valueHours + item.valueLabel).split(' ').join('')}`,
            payload: item
        })));
        const id = allRoute.find((item: any) => `${item.id}${item.title}` === [form.route][0])?.id
        const selectDoc = allRoute.find((item: any) => item.id === id)
        setEditDoc(selectDoc)
        setEditId(id)
    }, [form.route])

    useEffect(() => {
        const dateSave = allRoute.find((item: any) => `${item.id}${item.title}` === [form.route][0])?.dateSave ?? allRoute.find((item: any) => `${item.id}${item.title}` === [form.route][0])?.dataSave
        setSelectDateSend(dateSave?.map((item: dateSaveType) => ({
            label: item.label,
            value: `${(item.label + item.valueHours + item.valueLabel).split(' ').join('')}`,
            payload: item
        })).find((item: any) => item.value === form.date)?.payload)
        return () => {
        }
    }, [form.date])
    return ReactDOM.createPortal(<div
        className={classNames(styles.root)}>
        <div
            className={classNames(styles.underlay)} onClick={onClose}/>

        <div className={classNames(styles.modal)}>
            <div className={classNames(styles.body)}>
                <form className={styles.form}>
                    <h3>
                        Забронируйте путешествие на нашем сайте!
                    </h3>
                    <Dropdown
                        value={[form.route]}
                        options={route}
                        onChange={data => setValue(data.value, 'route')}
                    />
                    <Dropdown
                        disabled={!!![form.route][0]}
                        value={[form.date || '']}
                        options={date ? date : []}
                        onChange={data => {
                            setValue(data.value.split(' ').join(''), 'date')
                        }}
                    />
                    <Input
                        className={styles.input}
                        placeholder={'Номер телефона'}
                        value={form.number}
                        onChange={data => setValue(data, 'number')}
                    />
                    <Input
                        className={styles.input}
                        placeholder={'Ф.И.О.'}
                        value={form.name}
                        onChange={data => setValue(data, 'name')}
                    />
                    <Input
                        className={styles.input}
                        placeholder={'Электронная почта'}
                        value={form.email}
                        onChange={data => setValue(data, 'email')}
                    />

                    <Button disabled={!check} onClick={(e: any) => sendMail(e)} size={ButtonSizeType.PRIMARY}
                            calsess={styles.button}>
                        Отправить
                    </Button>

                    <div className={styles.ckeckboxWrapper}>
                        <div className={styles.ckeckbox}>
                            <Checkbox
                                checked={check}
                                onCheck={checkFun}
                            />
                        </div>
                        <span>Даю согласие на обработку персональных <br/> данных</span>
                    </div>
                </form>
            </div>
        </div>

    </div>, container);
}

export default ReserveRouteForms
