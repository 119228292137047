import React, { FC, useContext } from 'react'
import { BlogType } from '../Blog'
import styles from '../Blog.module.scss'
import { BlogContext } from '../BlogContext'

type Props = {
    data: BlogType
}

const BlogCard: FC<Props> = ({ data }) => {
    const [blog, setBlog, blogItem, setBlogItem] = useContext(BlogContext)
    const seeMore = () => {
        setBlogItem(data.id)
    }
    return (
        <div className={styles.wrapperCard}>
            <div className={styles.cardImage} >
                <img src={data.img} className={styles.image} />
                <h4 className={styles.cardImageTitle}>{data.title}</h4>
            </div>
            <div className={styles.cardDescription}>
                <p className={styles.cardDescriptionParagraph}>{data.description}</p>
                <span onClick={seeMore} className={styles.cardDescriptionReadMore}>Читать далее</span>
            </div>
        </div>)
}

export default BlogCard