import React from 'react'
import styles from './Perfomance.module.scss'
import center from '../../../../../assets/image/picture_vertical.jpg'
import img1 from '../../../../../assets/image/picture1.jpg'
import img2 from '../../../../../assets/image/picture2.jpg'
import img3 from '../../../../../assets/image/picture3.jpg'
import img4 from '../../../../../assets/image/picture4.jpg'
import classNames from 'classnames'
import Button, { ButtonSizeType } from '../../../../simple/Button/Button'
import { Link } from 'react-router-dom'

type Props = {}

const Perfomance = (props: Props) => {
    return (
        <section className={styles.perfomance}>
            <div className={classNames(styles.container, styles.transform)}>
                <h1 className={styles.perfomanceTitle}>
                    Насколько ярким<br /> может быть ваш отдых?
                </h1>
                <div className={styles.cardPhotos}>
                    <div className={classNames(styles.areaBlock1, styles.areaBlock_item)}>
                        <img src={img1} alt="" />

                    </div>
                    <div className={classNames(styles.areaBlock2, styles.areaBlock_item)}>
                        <img src={img3} alt="" />

                    </div>
                    <div className={classNames(styles.center, styles.areaCenter)}>
                        <img src={center} />

                    </div>
                    <div className={classNames(styles.areaBlock3, styles.areaBlock_item)}>
                        <img src={img2} alt="" />

                    </div>
                    <div className={classNames(styles.areaBlock4, styles.areaBlock_item)}>
                        <img src={img4} alt="" />

                    </div>
                </div>
                <div className={styles.buttonWrapper}>
                    <Link to={'/gallery'}>  <Button className={styles.button} size={ButtonSizeType.SMALL}>
                        Перейти в галерею
                    </Button></Link>
                </div>
            </div>
        </section>
    )
}

export default Perfomance