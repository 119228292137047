import React, { FC, useEffect, useState } from 'react'
import { CalendarSaveType, dateSaveType, RouteSectionType } from '../../../../../Types/Types'
import Layout from '../../../../complex/Layout/Layout'
import Button from '../../../../simple/Button/Button'
import Section from '../../../../simple/Section/Section'
import { SectMain } from '../MainPage/MainPageServices'
import styles from './Travel.module.scss'
import img from '../../../../../assets/image/travelImg.jpg'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../../../../../utilits/firebaseConfig'
import { async } from '@firebase/util'
import TravelItem from './parts/TravelItem'
type Props = {}



export type TravelType = {
    description: string
    advantages: string
    cost: string
    food: string
    id: string
    img: string
    length: string
    title: string
    time: string
    dateSave: dateSaveType[]
    saveDateInCalendar: CalendarSaveType[]
}



const Travel = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [travel, setTravel] = useState<TravelType[]>([])
    const travelColl = collection(db, 'travel')
    const getTravel = async () => {
        setLoading(false)
        const data = await getDocs(travelColl)
        setTravel(data.docs.map((item: any) => ({ ...item.data(), id: item.id })))
        setLoading(true)
    }

    useEffect(() => {
        getTravel()
    }, [])
    const SectSub: FC = (): JSX.Element => {
        return (
            <Section label="Путешествия:" sections={travel.map(item => ({ label: item.title, rout: 'services/travel' }))} />
        )
    }
    return (
        <Layout mainSection={<SectMain />} className={styles.layoutExcursion} subSection={<SectSub />}>
            <section className={styles.travel}>
                <div className={styles.title}>
                    <h3 className={styles.importansDetalis}>Важные детали!</h3>
                    <p>В маршрутах, где включён завтрак и обед, гости выбирают блюда по меню заранее , чтобы наш капитан успел приобрести нужные продукты и доставить их на плот.</p>
                    <p>Дополнительные блюда также обговариваются заранее и оплачиваются отдельно.</p>
                </div>
                <div className={styles.cardWrapper}>
                    {loading && travel.map((item: TravelType) => <TravelItem data={item} />)}
                </div>
            </section>
        </Layout>
    )
}

export default Travel
