import React, { FC } from 'react'
import { ReviewsType } from '../Rewievs'
import styles from '../Rewievs.module.scss'
type Props = {
    data: ReviewsType
}

const ReviewItem: FC<Props> = ({ data }) => {
    return (
        <div className={styles.card}>
            {/* <div className={styles.imgCard}>
                            <img src={img} />
                        </div> */}
            <div className={styles.bottomReviews}>
                <div className={styles.topReviewsTitle}>
                    <h4>{data.name}</h4>
                    <p>Дата: {data.date}</p>
                </div>
                <div className={styles.bottomReviewsTitle}>
                    <p>{data.comment}</p>
                </div>
            </div>
        </div>
    )
}

export default ReviewItem