import React, { FC, useEffect, useState } from 'react'
import { RouteSectionType } from '../../../../../Types/Types'
import Layout from '../../../../complex/Layout/Layout'
import Section from '../../../../simple/Section/Section'
import { SectMain } from '../MainPage/MainPageServices'
import styles from './Orders.module.scss'
import honey from '../../../../../assets/image/honey.jpg'
import classNames from 'classnames'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../../../../../utilits/firebaseConfig'
import OrderItem from './parts/OrderItem'
type Props = {}
export type OrderType = {
    title: string
    img: string
    description: string
    price: string
}

export const SectSub: FC = (): JSX.Element => {
    return (
        <Section label="Товары" />
    )
}
const Orders = (props: Props) => {
    const [orders, setOrders] = useState<OrderType[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const ordersColl = collection(db, 'order')
    const getOrders = async () => {
        setLoading(false)
        const data = await getDocs(ordersColl)

        setOrders(data.docs.map((doc: any) => ({ ...doc.data(), id: doc.id })))
        setLoading(true)
    }

    useEffect(() => {
        getOrders()
    }, [])

    return (
        <Layout mainSection={<SectMain />} subSection={<SectSub />}>
            <section className={classNames(styles.orders)}>
                {loading && orders.map((item: OrderType) => <OrderItem data={item} />)}
            </section>
        </Layout>
    )
}

export default Orders