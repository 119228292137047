import React, { FC } from 'react'
import { Footer } from './Footer'
import { Header } from './Header'
import styles from './Layout.module.scss'

type Props = {
    children: React.ReactNode
}

const Layout: FC<Props> = ({ children }) => {
    return (
        <>
            <div className={styles.container}>
                <Header />
                {children}
                <Footer />
            </div>
        </>
    )
}

export default Layout