import React, { FC } from 'react'
import Button from '../../simple/Button/Button'
import styles from './MainPage.module.scss'
import About from './parts/About/About'
import Calendar from './parts/Calendar/Calendar'
import Contacts from './parts/Contacts/Contacts'
import Forms from './parts/Forms/Forms'
import Perfomance from './parts/Perfomance/Perfomance'
import Reviews from './parts/Reviews/Reviews'
import Subscription from './parts/Subscription/Subscription'
import TopMainPage from './parts/Top/Top'
import WhereTrust from './parts/WhereTrust/WhereTrust'
type Props = {}

const MainPage: FC<Props> = ({ }) => {
  return (
    <>
      <TopMainPage />
      <Calendar />
      <About />
      <WhereTrust />
      <Perfomance />/
      <Forms />
      <Reviews />
      <Subscription />
      <Contacts />
    </>
  )
}

export default MainPage