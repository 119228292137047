import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'
import styles from './Layout.module.scss'
type Props = {
  children: ReactNode
  mainSection?: JSX.Element
  subSection?: JSX.Element
  column?: string
  className?: string
}

const Layout: FC<Props> = ({ className, children, mainSection, column, subSection }) => {
  return (
    <section className={classNames(styles.layout, styles.container, className)}>
      <div className={styles.column}>
        {mainSection && <div className={styles.mainSection}>
          {mainSection}
        </div>}
        {subSection && <div className={styles.subSection}>
          {subSection}
        </div>}
        {!!column && <h4 className={styles.columnTitle}>{column}</h4>}
      </div>
      <div>
        {children}
      </div>
    </section>
  )
}

export default Layout