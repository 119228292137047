import classNames from 'classnames'
import React, { FC } from 'react'
import { ReactComponent as Arrow } from '../../../assets/icon/arrowCheck.svg'
import styles from './Checkbox.module.scss'
type Props = {
    onCheck: () => void
    checked: boolean
}

const Checkbox: FC<Props> = ({ checked, onCheck }) => {

    return (
        <div className={styles.checkWrapper} onClick={onCheck}>
            {checked && <span className={styles.arrow}>
                <Arrow />
            </span>}
        </div >
    )
}

export default Checkbox