import React, { useState } from 'react'
import styles from './Reviews.module.scss'
import photo from '../../../../../assets/image/reviewsPhoto.png'
import Button from '../../../../simple/Button/Button'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
type Props = {}

const Reviews = (props: Props) => {
    let sizePage = document.documentElement.clientWidth
    const [size, setSize] = useState<number>(sizePage)
    window.addEventListener('resize', () => {
        setSize(sizePage)
    })
    return (
        <section className={classNames(styles.reviews, styles.container)}>

            <h1>Отзывы наших <br /> довольных гостей</h1>
            <div className={styles.itemWrapper}>
                <div className={styles.item}>
                    <div className={styles.wrapper}>
                        {/* <div className={styles.img}>
                            <img src={photo} />
                        </div> */}
                        <div className={styles.title}>
                            <h2>Ефимов Марк Александрович</h2>
                        </div>
                    </div>
                    <div className={styles.reviews}>
                        <p>Приятная атмосфера и веселая музыка, отличное чувство юмора капитана было огромным плюсом. Мы с друзьями весело провели время и вкусно поели. Продукты замечаткельного качества, всё свежее и очень вкусное. Спасибо!</p>
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.wrapper}>
                        {/* <div className={styles.img}>
                            <img src={photo} />
                        </div> */}
                        <div className={styles.title}>
                            <h2>Борецкий Алексей Артурович</h2>
                        </div>
                    </div>
                    <div className={styles.reviews}>
                        <p>Ездили на выходные двумя семьями на рыбалку. Отлично провели время, всё понравилось! У ребят есть своя продукция мёда, своя пасека, дети оценили. Надеемся, что получится чаще вот так выбираться! Очень здорово!</p>
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.wrapper}>
                        {/* <div className={styles.img}>
                            <img src={photo} />
                        </div> */}
                        <div className={styles.title}>
                            <h2>Ушаков Виталий Александрович</h2>
                        </div>
                    </div>
                    <div className={styles.reviews}>
                        <p>Спасибо большое! Одно из самых лучших путешествий. Приятная, комфортная и очень познавательная экскурсия с Антоном, радовал нас вкусной кухней и интересными рассказами во время всей поездки.</p>
                    </div>
                </div>
            </div>
            <div className={styles.button}>
                <Button calsess={styles.buttonElement}>
                    <Link to='/rewievs'>Посмотреть все</Link>
                </Button>
            </div>
        </section>
    )
}

export default Reviews