import React from 'react'
import Button from '../../../../simple/Button/Button'
import styles from './Subscription.module.scss'
import boat from '../../../../../assets/image/boat.jpg'
type Props = {}

const Subscription = (props: Props) => {
    return (
        <section className={styles.subscription}>
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <div className={styles.title}>
                        <h3>Подпишитесь на нашу страницу Вконтакте и получите гарантированную скидку 5%  </h3>
                        <a href='https://vk.com/plot.matroskin'>  <Button className={styles.button}>Подписаться</Button></a>
                    </div>
                    <div className={styles.img}>
                        <img src={boat} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Subscription