import { ChangeEvent, FC, MouseEventHandler, useCallback } from 'react';
import { OptionType } from './Dropdown';
import styles from './Dropdown.module.scss'



type Props = {
    option: OptionType
    onClick: (data: {
        value: string,
        keyName?: string
    }) => void
    isMultiple?: boolean
    isChecked?: boolean
    onDelete?: (value: string) => void
}

const Item: FC<Props> = ({
    option,
    onClick,
    isMultiple,
    isChecked,
    onDelete
}) => {


    return (
        <div className={styles.itemRoot}>
            <div className={styles.itemWrapper}>
                <div
                    // onMouseEnter={() => setShowExtra(true)}
                    // onMouseLeave={() => setShowExtra(false)}
                    onClick={(e) => {
                        e.stopPropagation()
                        onClick({ value: option.value, keyName: option.keyName })
                    }}
                    className={styles.options}
                >
                    {option.label}

                </div>
                <div className={styles.extraWrapper}>
                    {option.options &&
                        option.options?.length > 0 &&
                        <div className={styles.extraMenu}>
                            {option.options.map((item) => {
                                return <Item key={item.value} option={item} onClick={() => onClick({ value: item.value, keyName: item.keyName })} />
                            })
                            }
                        </div>}
                </div>
            </div>
        </div>
    )
}
export default Item