import React, { FC, useContext } from 'react'
import styles from '../Blog.module.scss'
import { ReactComponent as Close } from '../../../../assets/icon/close.svg'
import { BlogType } from '../Blog'
import { BlogContext } from '../BlogContext'

type Props = {
    data: BlogType
}

const BlogItem: FC<Props> = ({ data }) => {
    const [blog, setBlog, blogItem, setBlogItem] = useContext(BlogContext)

    return (
        <div className={styles.advancedContent}>
            <div className={styles.advancedContentImg}>
                <img src={data.advancedImg} />
            </div>
            <div className={styles.advancedContentInfo}>
                <h3 className={styles.title}>{data.advancedTitle}</h3>
                <p className={styles.titleDescription}>{data.description}</p>
                <ul className={styles.listWrapper}>
                    {data.listAdvanced.map((item, i) => {
                        return <li key={i} className={styles.listItem}>{item}</li>
                    })}
                </ul>
                <span onClick={() => setBlogItem(undefined)} className={styles.close}> <Close /> </span>
            </div>
        </div>)
}

export default BlogItem