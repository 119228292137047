import classNames from 'classnames'
import React, { FC, useState } from 'react'
import styles from './Input.module.scss'
type Props = {
    value: string
    onChange: (value: string) => void
    placeholder?: string
    className?: string
}

export enum EActive {
    BLUR = 'blur',
    FOCUS = 'focus'
}

const Input: FC<Props> = ({ value, placeholder, className, onChange }) => {
    const [active, setActive] = useState<EActive>(EActive.BLUR)
    return (
        <div className={classNames(styles.className, styles.wrapperInput, {
            [styles.blur]: active === EActive.BLUR,
            [styles.focus]: active === EActive.FOCUS,
        })}>
            <input
                onFocus={() => setActive(EActive.FOCUS)}
                onBlur={() => setActive(EActive.BLUR)}
                value={value}
                onChange={data => onChange(data.target.value)}
                className={styles.input}
                type="text"
                placeholder={placeholder ? placeholder : ''}
            />
        </div>
    )
}

export default Input