import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react'
import DatePicker from 'react-date-picker';
import { OptionType } from '../../../../simple/Dropdown/Dropdown';
import styles from "./Calendar.module.scss";
import { ReactComponent as Arrow } from '../.././../../../assets/icon/arrowCalendar.svg'
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../../../utilits/firebaseConfig';
import { TravelType } from '../../../Servives/parts/Travel/Travel';
import { ExcursionType } from '../../../Servives/parts/Excursion/Excursion';
import { CalendarSaveType } from '../../../../../Types/Types';
import ReserveRouteForms from '../../../../complex/reserveRouteForms/ReserveRouteForms';
import { array1, array2 } from '../Top/Top';
type Props = {}
enum MonthType {
    January = 'January',
    February = 'February',
    March = 'March',
    April = 'April',
    May = 'May',
    June = 'June',
    July = 'July',
    August = 'August',
    September = 'September',
    October = 'October',
    November = 'November',
    December = 'December',
}

type monthTranslateType = {
    [key in MonthType]: string
}
const maximumNumberOfHoursBooking = 10 * 4
const monthTranslateNumber = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
]
const monthTranslateWord: monthTranslateType = {
    'January': 'Январь',
    'February': 'Февраль',
    'March': 'Март',
    'April': 'Апрель',
    'May': 'Май',
    'June': 'Июнь',
    'July': 'Июль',
    'August': 'Август',
    'September': 'Сентябрь',
    'October': 'Октябрь',
    'November': 'Ноябрь',
    'December': 'Декабрь',
}



const Calendar = (props: Props) => {
    const date = new Date
    const [month, setMonth] = useState<number>(date.getMonth() + 1)

    const [week, setWeek] = useState<OptionType[]>([])
    const [year, setYear] = useState<number>(date.getFullYear())
    const [travel, setTravel] = useState<TravelType[]>([])
    const [excursion, setExcursion] = useState<ExcursionType[]>([])
    const [allRoute, setAllRoute] = useState<any>([])
    const [arraySave, setSave] = useState<CalendarSaveType[]>([])
    const [reserve, setReserve] = useState<boolean>(false)
    const [travelCurrent, setTravelCurrent] = useState<string>('')
    const [currentIdTravel, setCurrentIdTravel] = useState<number>(0)
    const birthday = new Date(`${Object.keys(monthTranslateWord)[month - 1]} 1, ${year} 01:00:00`);
    function quantityDaInMonth(month: number) {
        return new Date(year, month, 0).getDate()
    }
    const day1 = birthday.getDay();
    // Sunday - Saturday : 0 - 6
    // first day week may 1 - monday
    // quantit prev month
    const dayQuantityPrev: number = quantityDaInMonth(month - 1)

    // quantit current month
    const dayQuantity: number = quantityDaInMonth(month)
    // quantit next month
    const dayQuantityNext: number = quantityDaInMonth(month + 1)


    // fill week calendar
    const fillDate = useCallback(() => {
        let week = []
        const firstMonthDay = day1 === 0 ? 6 : day1 - 1
        for (let i = 0; i < firstMonthDay; i++) {
            const obj: OptionType = {
                label: dayQuantityPrev - i + "",
                value: `День: ${dayQuantityPrev - i} Месяц: ${month - 1} Год: ${year}`
            }
            week.push(obj)
        }
        week.reverse()
        for (let i = 0; i < dayQuantity; i++) {
            const obj: OptionType = {
                label: i + 1 + "",
                value: `День: ${i + 1} Месяц: ${month} Год: ${year}`
            }
            week.push(obj)
        }
        for (let i = 0; i < dayQuantityNext; i++) {
            const obj: OptionType = {
                label: i + 1 + "",
                value: `День: ${i + 1} Месяц: ${month + 1} Год: ${year}`
            }

            week.push(obj)
        }
        return week
    }, [month])



    // fetch base excursion

    const travelColl = collection(db, 'travel')
    const excursionColl = collection(db, 'excursion')

    const getRoute = async () => {
        const travel = await getDocs(travelColl)
        setTravel(travel.docs.map((doc: any) => ({ ...doc.data(), id: doc.id })))
        const excursion = await getDocs(excursionColl)
        setExcursion(excursion.docs.map((doc: any) => ({ ...doc.data(), id: doc.id })))
    }

    useEffect(() => {
        if (month > 12) {
            setYear(prev => prev + 1)
            setMonth(prev => 1)
            return
        }
        if (month < 1) {
            setMonth(prev => 12)
            setYear(prev => prev - 1)
            return
        }
        setWeek([])
    }, [month])

    useEffect(() => {
        setAllRoute([...excursion, ...travel])
    }, [travel, excursion])

    useEffect(() => {
        getRoute()
    }, [allRoute.length, currentIdTravel])

    useEffect(() => {
        setWeek(fillDate())
    }, [month])
    useEffect(() => {
        const arr: any[] = []
        const arr3 = allRoute.map((item: any) => item.saveDateInCalendar || [])

        for (let i = 0; i < arr3.length; i++) {
            for (let j = 0; j < arr3[i].length; j++) {
                const element = arr3[i][j];
                if (element) {
                    arr.push(element)
                }
            }
        }
        setSave(arr)
    }, [allRoute.length])


 return (
        <>

            <section className={styles.calendar}>
                <div className={styles.container}>
                    <h1>Забронируйте путешествие <br /> на нашем сайте</h1>
                    <div className={styles.calendarWrapper}>

                        <div className={styles.selectMonth}>
                            <span onClick={() => setMonth(prev => prev - 1)} className={styles.arrowLeftMonth}><Arrow /></span>
                            <div className={styles.titleMonth}>{monthTranslateNumber[month - 1]} {year}</div>
                            <span onClick={() => setMonth(prev => prev + 1)} className={styles.arrowRightMonth}><Arrow /></span>
                        </div>
                        <table className={styles.calendarTable}>
                            < >
                                <tr className={styles.header}>
                                    <th>ПН</th>
                                    <th>ВТ</th>
                                    <th>СР</th>
                                    <th>ЧТ</th>
                                    <th>ПТ</th>
                                    <th>СБ</th>
                                    <th>ВС</th>
                                </tr>
                            </>
                            < >
                                <tr className={styles.body}>
                                    {week.slice(0, 7).map(item => (
                                        <>
                                            <td className={classNames({
                                                [styles.green]: arraySave?.filter(itemArr => itemArr.date === item.value)
                                                    .map(item => item.value)
                                                    .reduce((prev, current) => +prev + +current, 0) > 0 ?
                                                    arraySave?.filter(itemArr => itemArr.date === item.value)
                                                        .map(item => item.value)
                                                        .reduce((prev, current) => +prev + +current, 0) < maximumNumberOfHoursBooking
                                                    &&
                                                    arraySave?.filter(itemArr => itemArr.date === item.value)
                                                        .map(item => item.value)
                                                        .reduce((prev, current) => +prev + +current, 0) >= 1 : false,
                                                [styles.red]: arraySave?.filter(itemArr => itemArr.date === item.value)
                                                    .map(item => item.value).reduce((prev, current) => +prev + +current, 0) >= maximumNumberOfHoursBooking,
                                            })}>{item.label}</td>
                                        </>
                                    ))}
                                </tr>
                                <tr className={styles.body}>
                                    {week.slice(7, 14).map(item => (
                                        <>
                                            <td className={classNames({
                                                [styles.green]: arraySave?.filter(itemArr => itemArr.date === item.value)
                                                    .map(item => item.value)
                                                    .reduce((prev, current) => +prev + +current, 0) > 0 ?
                                                    arraySave?.filter(itemArr => itemArr.date === item.value)
                                                        .map(item => item.value)
                                                        .reduce((prev, current) => +prev + +current, 0) < maximumNumberOfHoursBooking
                                                    &&
                                                    arraySave?.filter(itemArr => itemArr.date === item.value)
                                                        .map(item => item.value)
                                                        .reduce((prev, current) => +prev + +current, 0) >= 1 : false,
                                                [styles.red]: arraySave?.filter(itemArr => itemArr.date === item.value)
                                                    .map(item => item.value).reduce((prev, current) => +prev + +current, 0) >= maximumNumberOfHoursBooking,
                                            })}>{item.label}</td>
                                        </>
                                    ))}
                                </tr>
                                <tr className={styles.body}>
                                    {week.slice(14, 21).map(item => (
                                        <>
                                            <td className={classNames({
                                                [styles.green]: arraySave?.filter(itemArr => itemArr.date === item.value)
                                                    .map(item => item.value)
                                                    .reduce((prev, current) => +prev + +current, 0) > 0
                                                    ?
                                                    arraySave?.filter(itemArr => itemArr.date === item.value)
                                                        .map(item => item.value)
                                                        .reduce((prev, current) => +prev + +current, 0) < maximumNumberOfHoursBooking
                                                    &&
                                                    arraySave?.filter(itemArr => itemArr.date === item.value)
                                                        .map(item => item.value)
                                                        .reduce((prev, current) => +prev + +current, 0) >= 1 : false,



                                                [styles.red]: arraySave?.filter(itemArr => itemArr.date === item.value)
                                                    .map(item => item.value).reduce((prev, current) => +prev + +current, 0) >= maximumNumberOfHoursBooking,
                                            })}>{item.label}</td>
                                        </>
                                    ))}
                                </tr>
                                <tr className={styles.body}>
                                    {week.slice(21, 28).map(item => (
                                        <>
                                            <td className={classNames({
                                                [styles.green]: arraySave?.filter(itemArr => itemArr.date === item.value)
                                                    .map(item => item.value)
                                                    .reduce((prev, current) => +prev + +current, 0) > 0 ?
                                                    arraySave?.filter(itemArr => itemArr.date === item.value)
                                                        .map(item => item.value)
                                                        .reduce((prev, current) => +prev + +current, 0) < maximumNumberOfHoursBooking
                                                    &&
                                                    arraySave?.filter(itemArr => itemArr.date === item.value)
                                                        .map(item => item.value)
                                                        .reduce((prev, current) => +prev + +current, 0) >= 1 : false,
                                                [styles.red]: arraySave?.filter(itemArr => itemArr.date === item.value)
                                                    .map(item => item.value).reduce((prev, current) => +prev + +current, 0) >= maximumNumberOfHoursBooking,
                                            })}>{item.label}</td>
                                        </>
                                    ))}
                                </tr>
                                <tr className={styles.body}>
                                    {week.slice(28, 35).map(item => (
                                        <>
                                            <td className={classNames({
                                                [styles.green]: arraySave?.filter(itemArr => itemArr.date === item.value)
                                                    .map(item => item.value)
                                                    .reduce((prev, current) => +prev + +current, 0) > 0 ?
                                                    arraySave?.filter(itemArr => itemArr.date === item.value)
                                                        .map(item => item.value)
                                                        .reduce((prev, current) => +prev + +current, 0) < maximumNumberOfHoursBooking
                                                    &&
                                                    arraySave?.filter(itemArr => itemArr.date === item.value)
                                                        .map(item => item.value)
                                                        .reduce((prev, current) => +prev + +current, 0) >= 1 : false,
                                                [styles.red]: arraySave?.filter(itemArr => itemArr.date === item.value)
                                                    .map(item => item.value).reduce((prev, current) => +prev + +current, 0) >= maximumNumberOfHoursBooking,
                                            })}>{item.label}</td>
                                        </>
                                    ))}
                                </tr>
                                <tr className={styles.body}>
                                    {week.slice(35, 42).map(item => (
                                        <>
                                            <td className={classNames({
                                                [styles.green]: arraySave?.filter(itemArr => itemArr.date === item.value)
                                                    .map(item => item.value)
                                                    .reduce((prev, current) => +prev + +current, 0) > 0 ?
                                                    arraySave?.filter(itemArr => itemArr.date === item.value)
                                                        .map(item => item.value)
                                                        .reduce((prev, current) => +prev + +current, 0) < maximumNumberOfHoursBooking
                                                    &&
                                                    arraySave?.filter(itemArr => itemArr.date === item.value)
                                                        .map(item => item.value)
                                                        .reduce((prev, current) => +prev + +current, 0) >= 1 : false,
                                                [styles.red]: arraySave?.filter(itemArr => itemArr.date === item.value)
                                                    .map(item => item.value).reduce((prev, current) => +prev + +current, 0) >= maximumNumberOfHoursBooking,
                                            })}>{item.label}</td>
                                        </>
                                    ))}
                                </tr>
                            </>
                        </table>
                        <div className={styles.saveMark}>
                            <div className={styles.saveMarkContainer}>

                                <span className={classNames(styles.itemMark, styles.redMark)} ></span><p className={styles.saveMarkItem}>мест нет</p>
                            </div>
                            <div className={styles.saveMarkContainer}>

                                <span className={classNames(styles.itemMark, styles.greenMark)} ></span><p className={styles.saveMarkItem}>места еще есть, поспешите</p>
                            </div>
                            <div className={styles.saveMarkContainer}>

                                <span className={classNames(styles.itemMark, styles.blackMark)} ></span><p className={styles.saveMarkItem}>доступно для брони</p>
                            </div>
                            <div className={styles.dop}>
                                *Чтобы забронировать экскурсию на закате, звоните по телефону: <a className={styles.numberLink} href='tel:+79062377077'> +79062377077</a>
                            </div>
                        </div>
                        <div onClick={() => setReserve(true)} className={styles.goToSave}>Перейти к бронированию</div>
                    </div>
                </div>
            </section >
            {
                reserve && <ReserveRouteForms
                    dropDownOne={array1}
                    dropDownTwo={array2}
                    onClose={() => setReserve(false)}
                />
            }
        </>
    )
}

export default Calendar
