import produce from 'immer'
import { useEffect, useState } from 'react'
import { dateSaveType, FormType } from '../../../../../Types/Types'
import Button, { ButtonSizeType } from '../../../../simple/Button/Button'
import Checkbox from '../../../../simple/Checkbox/Checkbox'
import Dropdown, { OptionType } from '../../../../simple/Dropdown/Dropdown'
import Input from '../../../../simple/Input/Input'
import SuccesReserve from '../../../../simple/SuccesReserve/SuccesReserve'
import styles from './Forms.module.scss'
import emailjs from '@emailjs/browser';
import { db } from '../../../../../utilits/firebaseConfig'
import { collection, doc, getDocs, setDoc } from 'firebase/firestore'
import { TravelType } from '../../../Servives/parts/Travel/Travel'
import { ExcursionType } from '../../../Servives/parts/Excursion/Excursion'
type Props = {}

const Forms = (props: Props) => {
    const [isSucces, setIsSucces] = useState<boolean>(false)
    const [check, setCheck] = useState<boolean>(false)
    const [form, setForm] = useState<FormType>({} as FormType)

    const [travel, setTravel] = useState<TravelType[]>([])
    const [excursion, setExcursion] = useState<ExcursionType[]>([])
    const [allRoute, setAllRoute] = useState<any>([])
    const [route, setRoute] = useState<OptionType[]>([])
    const [date, setDate] = useState<OptionType[]>([])
    const [editId, setEditId] = useState<string>('')
    const [editDoc, setEditDoc] = useState<any>()
    const [selectDateSend, setSelectDateSend] = useState<dateSaveType>()
    const checkFun = () => {
        setCheck(prev => !prev)
    }
    const setValue = (value: string, key: keyof FormType) => {
        setForm(produce(
            draft => {
                const f: any = draft
                f[key] = value
            }
        ))
    }


    const testSet = async () => {
        // console.log(travel.find(item => item.id === editId));
        let type = ''
        if (travel.find(item => item.id === editId)) {
            type = 'travel'
        } else {
            type = 'excursion'
        }



        await setDoc(doc(db, type, editDoc.id), {
            ...editDoc, saveDateInCalendar: [
                ...editDoc.saveDateInCalendar,
                {
                    date: selectDateSend?.valueLabel,
                    value: selectDateSend?.valueHours
                }
            ]

        });

    }


    // console.log('====================================');
    // console.log(
    //     allRoute.find((item: any) =>
    //         `${item.id}${item.title}` === [form.route][0]
    //     )?.dateSave.map((item: dateSaveType) =>
    //         ({ label: item.label, value: `${(item.label + item.valueHours + item.valueLabel).split(' ').join('')}`, payload: item })
    //     ).find((item: any) => item.value === form.date)?.payload
    // );
    // console.log('====================================');

    const sendMail = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        if (check && form.number) {
            testSet()

            setIsSucces(true)

            emailjs.send("service_trgigim","template_rltt6n9", {
                route: form.route,
                date: form.date,
                number: form.number,
                name: form.name,
                email: form.email,
            }, 'NjCQ2BpbtE66zmwfU');
        }

    }
    const travelColl = collection(db, 'travel')
    const excursionColl = collection(db, 'excursion')

    const getRoute = async () => {
        const travel = await getDocs(travelColl)
        setTravel(travel.docs.map((doc: any) => ({ ...doc.data(), id: doc.id })))
        const excursion = await getDocs(excursionColl)
        setExcursion(excursion.docs.map((doc: any) => ({ ...doc.data(), id: doc.id })))
    }

    useEffect(() => {
        setAllRoute([...excursion, ...travel])
    }, [travel, excursion])
    useEffect(() => {
        setRoute(allRoute.map((item: any) => ({ label: item.title, value: `${item.id}${item.title}` })))
    }, [allRoute])

    useEffect(() => {
        getRoute()
    }, [])

    useEffect(() => {
        setValue('', 'date')
        const dateSave = allRoute.find((item: any) => `${item.id}${item.title}` === [form.route][0])?.dateSave ?? allRoute.find((item: any) => `${item.id}${item.title}` === [form.route][0])?.dataSave

        setDate(dateSave?.map((item: dateSaveType) =>
            ({ label: item.label, value: `${(item.label + item.valueHours + item.valueLabel).split(' ').join('')}`, payload: item })
        ));
        const id = allRoute.find((item: any) => `${item.id}${item.title}` === [form.route][0])?.id
        const selectDoc = allRoute.find((item: any) => item.id === id)
        setEditDoc(selectDoc)
        setEditId(id)
    }, [form.route])

    useEffect(() => {
        const dateSave = allRoute.find((item: any) => `${item.id}${item.title}` === [form.route][0])?.dateSave ?? allRoute.find((item: any) => `${item.id}${item.title}` === [form.route][0])?.dataSave

        setSelectDateSend(
            dateSave?.map((item: dateSaveType) =>
                ({ label: item.label, value: `${(item.label + item.valueHours + item.valueLabel).split(' ').join('')}`, payload: item })
            ).find((item: any) => item.value === form.date)?.payload
        )
        return () => {
        }
    }, [form.date])

    return (
        <>
            <section className={styles.formWrapper}>
                <div className={styles.container}>
                    <form className={styles.form}>
                        <h3>
                            Забронируйте путешествие на нашем сайте!
                        </h3>

                        <Dropdown
                            value={[form.route]}
                            options={route}
                            onChange={data => setValue(data.value, 'route')}
                        />
                        <Dropdown
                            disabled={!!![form.route][0]}
                            value={[form.date || '']}
                            // value={['16июня10:00-15:005День:13Mесяц:6Год:2022']}
                            options={date ? date : []}
                            onChange={data => {
                                setValue(data.value.split(' ').join(''), 'date')

                            }}
                        />
                        <Input
                            className={styles.input}
                            placeholder={'Номер телефона'}
                            value={form.number}
                            onChange={data => setValue(data, 'number')}
                        />
                        <Input
                            className={styles.input}
                            placeholder={'Ф.И.О.'}
                            value={form.name}
                            onChange={data => setValue(data, 'name')}
                        />
                        <Input
                            className={styles.input}
                            placeholder={'Электронная почта'}
                            value={form.email}
                            onChange={data => setValue(data, 'email')}
                        />

                        <Button disabled={!check} size={ButtonSizeType.VERY_BIG} onClick={(e) => sendMail(e)} className={styles.button}>
                            Отправить
                        </Button>

                        <div className={styles.ckeckboxWrapper}>
                            <div className={styles.ckeckbox}>
                                <Checkbox
                                    checked={check}
                                    onCheck={checkFun}
                                />
                            </div>
                            <span>Даю согласие на обработку персональных <br /> данных</span>
                        </div>
                    </form>
                </div>
            </section>
            {isSucces && <SuccesReserve
                onClose={() => setIsSucces(false)}
            />}
        </>

    )
}

export default Forms

/*
      <Dropdown
                value={['value']}
                options={opt}
                onChange={data => setValue(data.value)}
            />
*/
