import { FC, useEffect, useState } from 'react'
import { RouteSectionType } from '../../../Types/Types'
import Layout from '../../complex/Layout/Layout'
import Section from '../../simple/Section/Section'
import styles from './Rewievs.module.scss'
import Button from '../../simple/Button/Button'
import emailjs from '@emailjs/browser';

import produce from 'immer'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../../../utilits/firebaseConfig'
import ReviewItem from './parts/ReviewItem'
type Props = {}

type InputType = {
    text: string
    name: string
}
export type ReviewsType = {
    comment: string
    date: string
    name: string
}
export type SeasonType = {
    season: number
}
const Rewievs = (props: Props) => {
    const [input, setInput] = useState<InputType>({} as InputType)
    const rewievsColl = collection(db, 'reviews')
    const rewievsSeasonColl = collection(db, 'reviewsSeason')
    const [reviews, setReviews] = useState<ReviewsType[]>()
    const [season, setSeason] = useState<SeasonType[]>()
    const [loading, setLoading] = useState<boolean>(false)
    const setValue = (value: string, key: keyof InputType) => {
        setInput(produce(
            draft => {
                const f: any = draft
                f[key] = value
            }
        ))
    }
    
    const send = async () => {
        await emailjs.send("service_trgigim", "template_2itvti5", {
            name: input.name,
            comment: input.text,
        }, 'NjCQ2BpbtE66zmwfU');
        setValue('', 'name')
        setValue('', 'text')
    }
    const getReviews = async () => {
        setLoading(false)
        const reviewData = await getDocs(rewievsColl)
        setReviews(reviewData.docs.map((doc: any) => ({ ...doc.data(), id: doc.id })))
        const reviewSeasonData = await getDocs(rewievsSeasonColl)
        setSeason(reviewSeasonData.docs.map((doc: any) => ({ ...doc.data(), id: doc.id })))
        setLoading(true)
    }
    useEffect(() => {
        getReviews()
    }, [])

    const SectMain: FC = (): JSX.Element => {
        return (
            <Section label="Последние записи:" sections={season?.map(item => ({
                label: `Сезон ${item.season}`,
                rout: 'rewievs'
            }))} />
        )
    }


    return (
        <Layout mainSection={<SectMain />}>
            <section className={styles.rewievs}>
                <h3 className={styles.title}>Если вы путешествовали с нами, поделитесь впечатлениями. Мы хотим знать, что работаем не зря.
                </h3>
                <div className={styles.inputWrapper}>
                    <textarea value={input.text} onChange={(data) => setValue(data.target.value, 'text')} placeholder={'Напишите свои впечатления от отдыха...'} className={styles.input} />
                </div>
                <div className={styles.inputWrapper}>
                    <textarea value={input.name} onChange={(data) => setValue(data.target.value, 'name')} placeholder={'Ваше Ф.И.О.'} className={styles.input} />
                </div>
                <div className={styles.buttonWrapper}>
                    <Button onClick={send} className={styles.button}>
                        Отправить
                    </Button>
                </div>

                <div className={styles.cardWraper}>

                    {loading && reviews?.map((item) => <ReviewItem data={item} />)}
                </div>
            </section>
        </Layout>
    )
}

export default Rewievs