import React from 'react'
import styles from './WhereTrust.module.scss'
import { ReactComponent as Natural } from '../../../../../assets/icon/natural.svg'
import { ReactComponent as Wood } from '../../../../../assets/icon/wood.svg'
import { ReactComponent as Route } from '../../../../../assets/icon/route.svg'
import { ReactComponent as Menu } from '../../../../../assets/icon/menu.svg'
import { ReactComponent as Save } from '../../../../../assets/icon/save.svg'
import { ReactComponent as Photos } from '../../../../../assets/icon/photos.svg'


type Props = {}

const WhereTrust = (props: Props) => {
    return (
        <section className={styles.WhereTrust}>
            <div className={styles.container}>
                <h1 className={styles.whereTrust_title}>
                    Почему нам доверяют свой отдых?
                </h1>
                <div className={styles.card}>
                    <div className={styles.card_item}>
                        <div className={styles.img}>
                            <Natural />
                        </div>
                        <h4> Экопродукция</h4>
                        <p>Наш капитан ещё и пасечник! Попробуйте мёд и другие продукты капитанской пасеки</p>
                    </div>
                    <div className={styles.card_item}>
                        <div className={styles.img}>
                            <Wood />
                        </div>
                        <h4>Связь с природой</h4>
                        <p>С нами отдыхают, рыбачат и знакомятся с уникальной флорой и фауной Калининградской области</p>
                    </div>
                    <div className={styles.card_item}>
                        <div className={styles.img}>
                            <Route />
                        </div>
                        <h4>Маршруты</h4>
                        <p>Путешествия от часа до суток, выберите из 6 готовых программ или составьте свою   </p>
                    </div>
                    <div className={styles.card_item}>
                        <div className={styles.img}>
                            <Menu />
                        </div>
                        <h4> Вкусное меню</h4>
                        <p>Кок 4 разряда приготовит аппетитные блюда прямо на плоту. Меню обсуждаем заранее</p>
                    </div>
                    <div className={styles.card_item}>
                        <div className={styles.img}>
                            <Save />
                        </div>
                        <h4>Безопасность</h4>
                        <p>На нашем судне есть всё для безопасных прогулок на воде: жилеты, круги и конец Александрова</p>
                    </div>
                    <div className={styles.card_item}>
                        <div className={styles.img}>
                            <Photos />
                        </div>
                        <h4>Уникальные кадры</h4>
                        <p>Все маршруты проходят через пленительные виды. Сохраните себя на их фоне в памяти своего телефона</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhereTrust