import React, { FC, useEffect, useState } from 'react'
import { dateSaveType, RouteSectionType } from '../../../../../Types/Types'
import Layout from '../../../../complex/Layout/Layout'
import Section from '../../../../simple/Section/Section'
import { SectMain } from '../MainPage/MainPageServices'
import img from '../../../../../assets/image/jpg.jpg'
import Button from '../../../../simple/Button/Button'
import styles from './Excursion.module.scss'
import { db } from '../../../../../utilits/firebaseConfig'
import { collection, getDocs } from 'firebase/firestore'
import ExcursionItem from './parts/ExcursionItem'
type Props = {}




export type ExcursionType = {
    description: string
    cost: string
    food: string
    img: string
    time: string
    route: string
    title: string
    dateSave: dateSaveType[]
    saveDateInCalendar: string[]
    id: string
}



const Excursion = (props: Props) => {
    const excursionCollection = collection(db, 'excursion')
    const [excursion, setExcursion] = useState<any>([] as ExcursionType[])
    const [loading, setLoading] = useState<boolean>(false)

    const Route: RouteSectionType[] = excursion.map((item: ExcursionType) => ({ label: item.title, rout: 'services/excursion' }))
    const SectSub: FC = (): JSX.Element => {
        return (
            <Section label="Экскурсии:" sections={Route} />
        )
    }

    const getExcursion = async () => {
        setLoading(false)
        const data = await getDocs(excursionCollection)
        setExcursion(data.docs.map(doc => ({ ...doc.data(), id: doc.id })))
        setLoading(true)
    }

    useEffect(() => {
        getExcursion()
    }, [])


    return (
        <section>
            <Layout className={styles.layoutExcursion} mainSection={<SectMain />} subSection={<SectSub />}>
                <div className={styles.cardWrapper}>
                    {loading && excursion.map((item: any) => <ExcursionItem data={item} />)}
                </div>
            </Layout>
        </section>
    )
}

export default Excursion