import React, { useEffect, useState } from 'react'
import ReserveRouteForms from '../../../../complex/reserveRouteForms/ReserveRouteForms'
import Button from '../../../../simple/Button/Button'
import styles from '../../MainPage.module.scss'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../../../../../utilits/firebaseConfig'


type Props = {}



const TopMainPage = (props: Props) => {

    const [reserve, setReserve] = useState<boolean>(false)
    const reserveRoute = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        e.preventDefault()
        setReserve(true)
    }

    return (
        <>
            <section className={styles.MainPage}>
                <div className={styles.title}>
                    <div className={styles.titleText}>
                        Удивительные прогулки на катамаране
                    </div>
                    <p>
                        Водные путешествия по рекам и озёрам Калининградской области для любителей экотуризма и активного отдыха
                    </p>
                    <Button onClick={(e) => reserveRoute(e)} className={styles.button} >
                        Забронировать маршрут
                    </Button>
                </div>
            </section>
            {reserve && <ReserveRouteForms
                dropDownOne={array1}
                dropDownTwo={array2}
                onClose={() => setReserve(false)}
            />}
        </>
    )
}

export default TopMainPage

export const array1 = [
    {
        value: '11',
        label: '11',
    },
    {
        value: '12',
        label: '12',
    },
]

export const array2 = [
    {
        value: '21',
        label: '21',
    },
    {
        value: '22',
        label: '22',
    },
]
