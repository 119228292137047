import classNames from 'classnames'
import React from 'react'
import styles from './About.module.scss'
import imgOne from '../../../assets/image/aboutOne.png'
import imgTwo from '../../../assets/image/aboutTwo.png'
type Props = {}

const About = (props: Props) => {
    return (
        <section className={classNames(styles.about, styles.container)}>
            <div className={styles.title}>
                <div className={styles.topTitle}>
                    <h3>Антон, создатель «Плот Матроскин»</h3>
                    <p>Калининградец в третьем поколении и турист с 40-летним стажем. Он искренне любит воду и уже 30 лет не может от неё уйти. Летом Антон — капитан, повар, пасечник, рыбак, а в остальные времена года преподаватель и кандидат наук.</p>
                    <p>«Я влюблён в свою область, поэтому несу природу родных мест и экотуризм в массы»</p>
                </div>
                <div className={styles.bottomTitle}>
                    <h3>История создания проекта</h3>
                    <p>Мы строили плот тремя семьями, чтобы путешествовать и прививать нашим детям туризм и природу. Первое наше путешествие на нём длилось неделю. Мы прошли 350 км и были в восторге!
                    </p>
                    <p>В пандемийные времена плот доработали. Знакомые стали часто просить покатать их. Так и решили устраивать прогулки для местных и туристов.</p>
                    <p>Базу расположили в посёлке Матросово, поэтому назвали проект Плот Матроскин.
                    </p>
                </div>
            </div>
            <div className={styles.imgContainer}>
                <div className={styles.wrapperImg}>
                    <img src={imgOne} className={styles.imgOne} />
                    <img src={imgTwo} className={styles.imgTwo} />
                </div>
            </div>
        </section>
    )
}

export default About