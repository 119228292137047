import classNames from 'classnames'
import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react'
import styles from './Button.module.scss'

export enum ButtonSizeType {
    BIG = 'big',
    SMALL = 'small',
    VERY_BIG = 'very_big',
    PRIMARY = 'primary'
}

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
    children: ReactNode,
    size?: ButtonSizeType
    disabled?: boolean
    calsess?: string
}

const Button: FC<Props> = ({ children, calsess, disabled, size = ButtonSizeType.BIG, className, ...rest }) => {

    const classStyle = classNames(styles.button, {
        [styles.disabled]: disabled,
        [styles.yellow]: !disabled,
        [styles.big]: ButtonSizeType.BIG === size,
        [styles.small]: ButtonSizeType.SMALL === size,
        [styles.veryBig]: ButtonSizeType.VERY_BIG === size,
        [styles.primary]: ButtonSizeType.PRIMARY === size,
    }, calsess, className)
    return (
        <button
            className={classStyle}
            {...rest}
        >
            <span className={styles.contain}>
                {children}
            </span>
        </button>
    )
}

export default Button