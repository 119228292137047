import React, { FC } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { RouteSectionType } from '../../../Types/Types'
import styles from './Section.module.scss'

type Props = {
    label: string
    sections?: RouteSectionType[]
}

const Section: FC<Props> = ({ label, sections }) => {
    const markNotActive = {
        background: 'rgba(81, 83, 85, 0.5)'

    }
    const markActive = {
        background: '#FFCD00'
    }
    return (
        <div className={styles.section}>
            <h2>{label}</h2>
            <ul className={styles.itemWrapper}>
                {sections?.map((section, i) => {
                    return <li className={styles.item} key={i}>
                        <NavLink to={'/' + section.rout}>
                            {({ isActive }) => (
                                <>
                                    <span style={
                                        isActive ? markActive : markNotActive
                                    } className={styles.makr} />
                                    <h5>{section.label}</h5>
                                </>
                            )}

                        </NavLink>
                    </li>
                })}
            </ul>
        </div>
    )
}

export default Section