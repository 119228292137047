import React, { FC } from 'react'
import styles from './Footer.module.scss'
import cat from '../../../assets/icon/catLogo.png'
import { ReactComponent as Inst } from '../../../assets/icon/instagram.svg'
import { ReactComponent as Telegram } from '../../../assets/icon/telegram.svg'
import { ReactComponent as Vk } from '../../../assets/icon/vk.svg'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
type Props = {}

const Footer: FC<Props> = ({ }) => {
    return (
        <footer className={styles.footerBg}>
            <div className={classNames(styles.container, styles.footer)}>
                <div className={styles.footer_item}>
                    <div className={styles.logo}>
                        <img src={cat} alt="logo" />
                    </div>
                    <div className={styles.map}>
                        Калининградская обл., Полесский <br /> район, пос. Матросово, ул. <br /> Левобережная 16
                    </div>
                </div>
                <div className={styles.footer_item}>
                    <div className={styles.number}>
                        <div className={styles.numberTitle}>
                            Cвяжитесь с нами
                        </div>
                        <p>
                          <a href='tel:+79062377077'>  +7 (906) 237-70-77</a>
                        </p>
                    </div>
                    <div className={styles.telegram}>
                        <div className={styles.telegramTitle}>

                            Задайте вопрос в Telegram
                        </div>
                        <div className={styles.social}>
                            <ul>
                                {/* <li><a href='#'> <Inst className={styles.iconInst} /></a></li> */}
                                <li><a href='https://t.me/plot_matroskin'> <Telegram className={styles.iconTelegram} /></a></li>
                                <li><a href='https://vk.com/plot.matroskin'> <Vk className={styles.iconVk} /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={styles.footer_item}>
                    <ul>
                        <li className={styles.footerList}>
                            <Link to='/'>Главная</Link>

                        </li>
                        <li className={styles.footerList}>
                            <Link to='/about'> О нас</Link>

                        </li>
                        <li className={styles.footerList}>
                            <Link to='/services'>Услуги</Link>

                        </li>
                        <li className={styles.footerList}>
                            <Link to='/gallery'>Галерея</Link>

                        </li>
                    </ul>
                </div>
                <div className={styles.footer_item}>
                    <ul>
                        <li className={styles.footerList}>
                            <Link to='/blog'>Блог</Link>

                        </li>
                        <li className={styles.footerList}>
                            <Link to='/rewievs'> Отзывы</Link>

                        </li>
                        <li className={styles.footerList}>
                            <Link to='/contats'> Контакты</Link>

                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}

export default Footer