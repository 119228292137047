import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import {GallerySection, galleryType} from '../Gallery'
import styles from '../Gallery.module.scss'
type Props = {
    data: galleryType
    arr: GallerySection[]
}

const GalleryCard: FC<Props> = ({ data, arr }) => {
    const navigate = useNavigate()
    return (
        <div className={styles.wrapperCard}>
            <div className={styles.cardImage} >
                <img src={data.img} className={styles.image} />
                <h4 className={styles.cardImageTitle}>{data.title}</h4>
            </div>
            <div className={styles.cardDescription}>
                <p className={styles.cardDescriptionParagraph}>{data.description}
                </p>
                <span onClick={() => navigate('galleryitems/'+ data.id)} className={styles.cardDescriptionReadMore}>Открыть</span>
            </div>
        </div>)
}

export default GalleryCard
