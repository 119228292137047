import React, {FC, useContext, useEffect, useState} from 'react'
import {RouteSectionType} from '../../../Types/Types'
import Layout from '../../complex/Layout/Layout'
import Section from '../../simple/Section/Section'
import styles from './Gallery.module.scss'
import img from '../../../assets/image/galleryOne.jpg'
import img2 from '../../../assets/image/gallery2.jpg'
import img3 from '../../../assets/image/galleryThree.jpg'
import {collection, getDocs} from 'firebase/firestore'
import {db} from '../../../utilits/firebaseConfig'
import GalleryCard from './parts/GalleryCard'
import {GalleryContext} from './GalleryContext'

type Props = {}


export type galleryType = {
    title: string
    description: string
    id: string
    img: string
    imgItems: string[]
}

export interface GallerySection {
    title: string
    imgItems: string[]
    id: string
}

const Gallery = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [gallery, setgallery] = useState<galleryType[]>([])
    const [titleRight, setTitleRight] = useState<GallerySection[]>([])
    const galleryColl = collection(db, 'gallery')
    const getGallery = async () => {
        setLoading(false)
        const data = await getDocs(galleryColl)
        setgallery(data.docs.map((item: any) => ({...item.data(), id: item.id})))
        setTitleRight(data.docs.map((item: any) => ({...item.data(), id: item.id})))
        setLoading(true)
    }

    useEffect(() => {
        getGallery()
    }, [])

    return (<GalleryContext.Provider value={[titleRight, setTitleRight]}>
        <Layout mainSection={<SectGalleryMain/>}>
            <section className={styles.blog}>
                <div className={styles.wrapper}>
                    {loading && gallery.reverse().map((item, i) => <GalleryCard key={i} arr={titleRight}
                                                                                data={item}/>)}
                </div>
            </section>
        </Layout>
    </GalleryContext.Provider>)
}

export const SectGalleryMain: FC = (): JSX.Element => {
    const [titleRight, setTitleRight] = useContext(GalleryContext)

    const Route: RouteSectionType[] = titleRight.map(item => {
        return {
            label: item.title, rout: 'gallery/galleryitems/' + item.id
        }
    })


    return (<Section label="Разделы:" sections={Route}/>)
}
export default Gallery
