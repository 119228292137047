import React, { FC } from 'react'
import { OrderType } from '../Orders'
import styles from '../Orders.module.scss'
type Props = {
    data: OrderType
}

const OrderItem: FC<Props> = ({ data }) => {
    
    return (
        <div className={styles.card}>
            <div className={styles.img}>
                <img src={data.img} />
            </div>
            <div className={styles.title}>
                <h3>{data.title}</h3>
                <h4>{data.description}</h4>
                <h3>{data.price}</h3>
            </div>
        </div>)
}

export default OrderItem