import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD8mRpXqL5D1wquIJSfiBwYRtdXySPGCIY",
  authDomain: "plot-e4142.firebaseapp.com",
  projectId: "plot-e4142",
  storageBucket: "plot-e4142.appspot.com",
  messagingSenderId: "249548250471",
  appId: "1:249548250471:web:b3add8a56bb8ae672ca3c2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);




export const db  = getFirestore(app)