import React, { FC, useState } from 'react'
import styles from './About.module.scss'
// import stylesDecstop from './About.module.scss'
import stylesMedia from './AboutMedia.module.scss'
import one from '../../../../../assets/image/about_one.jpg'
import two from '../../../../../assets/image/about_two.png'
import three from '../../../../../assets/image/about_three.png'
type Props = {}

const About: FC<Props> = ({ }) => {


    return (
        <section className={styles.About}>
            <div className={styles.container}>
                <div className={styles.aboutContainer}>
                    <h2>О нас</h2>
                    <div className={styles.aboutDescription}>
                        <p>
                            Представьте. Утро, нагретое солнцем тело испаряет тепло, прилетают брызги прохладной воды — то, что нужно во время солнцепёка. Вы слышите бурление реки и голос капитана, он завёл очередную байку о жизни. Доносится яркий аромат свежеприготовленного завтрака.
                        </p>
                        <p>
                            Представили? Плот Матроскин — для тех, кто устаёт от городской суеты и мечтает оказаться вдали от рутины среди умиротворяющей природы.
                        </p>
                        <p>
                            Окунитесь в захватывающие экскурсии на плоту по водам Калининградской области в компании капитана, экскурсовода и повара в одном лице.
                        </p>
                    </div>
                </div>
                <div className={styles.oneImg}><img src={one} alt="one" />
                    <div className={styles.twoImg}><img src={two} alt="two" /></div>
                    <div className={styles.threeImg}><img src={three} alt="three" /></div>
                </div>
            </div>
        </section>
    )
}

export default About
