import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import {RouteSectionType} from '../../../../../Types/Types'
import Layout from '../../../../complex/Layout/Layout'
import Section from '../../../../simple/Section/Section'
import {SectMain} from '../MainPage/MainPageServices'
import styles from './Rules.module.scss'

type Props = {}

const Route: RouteSectionType[] = [{
    label: 'Перед бронированием', rout: 'services/rule'
}, {
    label: 'Что взять с собой', rout: 'services/rule'
}, {
    label: 'Безопасность', rout: 'services/rule'
}, {
    label: 'Праздники', rout: 'services/rule'
},]
export const SectSub: FC = (): JSX.Element => {
    return (<Section label="Правила посещения:" sections={Route}/>)
}
const Rules = (props: Props) => {
    return (<section>
            <Layout mainSection={<SectMain/>} subSection={<SectSub/>}>
                <div className={styles.itemWrapper}>
                    <h3>Перед записью:</h3>
                    <p>Мы любим путешествовать, а ещё любим кушать. Чтобы не работать в минус, мы <span className={styles.bold}>бронируем катамаран только от 6 человек.</span> Вы можете отправиться в путешествие в одиночку, но при оплате необходимо оплатить 6 билетов. Но для вашего удобства в понедельник и четверг объединяем группы. Обязательно уточните у капитана осталось ли свободное место.
                    </p>
                    <p>С 20 апреля по 20 июня запрет на ловлю рыбы.</p>
                    {/*<p>Максимальное количество пассажиров на одном катамаране 12. Всего флот состоит из 4 катамаранов и максимально может вместить 48 человек.</p>*/}
                    {/*<p>Максимальное количество пассажиров — 12.</p>*/}
                    {/*<p>Маленькие пассажиры самые любознательные, к нам можно <span className={styles.bold}>с детьми от 3-х лет.</span>*/}
                    {/*</p>*/}
                    {/*<p>Уважаем обязательных людей, поэтому <span className={styles.bold}>  бронируем путешествия по предоплате 10000 рублей.</span>*/}
                    {/*</p>*/}
                    <h3>Что взять с собой:</h3>
                    <p>Тёплую одежду.</p>
                    <p>Ленты от комаров.</p>
                    <p>По желанию — свои продукты, алкоголь, музыку на флешке.</p>
                    <h3>Наш капитан отвечает за безопасность, поэтому запрещается:</h3>
                    <p>Приносить с собой оружие, агрессивные мысли, плохое настроение, истерики.</p>
                    <p>Купаться без разрешения.</p>
                    <p> С 20 апреля по 20 июня ловить рыбу.</p>
                    {/*<p>С 20 апреля по 20 июня ходить под мотором и ловить рыбу.</p>*/}
                    <h3>Празднуйте и проводите на нашем катамаране:</h3>
                    <p>Дни рождения, корпоративы, мальчишники, девичники, тимбилдинги. Под звуки природы и уникальные пейзажи любое мероприятие пройдёт хорошо. А наш капитан добавит к первозданной природе колорита.</p>
                </div>
            </Layout>
        </section>)
}

export default Rules
