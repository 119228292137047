import React, { FC, KeyboardEventHandler, useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import classNames from "classnames";
import styles from './SuccesReserve.module.scss'
import { title } from 'process';
import Checkbox from '../../simple/Checkbox/Checkbox';
import Button, { ButtonSizeType } from '../../simple/Button/Button';
import Input from '../../simple/Input/Input';
import Dropdown, { OptionType } from '../../simple/Dropdown/Dropdown';
import produce from 'immer';
import { FormType } from '../../../Types/Types';
import { ReactComponent as Icon } from '../../../assets/icon/iconSuccesMail.svg'
import { ReactComponent as Close } from '../../../assets/icon/close.svg'

let container: any;
if (typeof window !== 'undefined') {

    const modalParent = document.createElement('div')
    modalParent.className = styles.modalParent
    document.body.appendChild(modalParent)

    container = modalParent;

}

type Props = {
    onClose?: () => void,

    close?: boolean
}



const SuccesReserve: FC<Props> = ({ onClose, close = true }) => {

    const closeESC = useCallback((event: KeyboardEventHandler<HTMLDivElement> | any): void => {
        if (event.key === 'Escape') {
            if (onClose) {
                onClose()
            }
        }
    }, [onClose])

    // Установка/удаление обработчика события на документе.
    useEffect(() => {
        document.addEventListener('keydown', closeESC, false)
        return () => {
            document.removeEventListener('keydown', closeESC, false)
        }
    }, [closeESC])



    return ReactDOM.createPortal(
        <div
            className={classNames(styles.root)}>
            <div
                className={classNames(styles.underlay)} onClick={onClose} />

            <div className={classNames(styles.modal)}>
                    <div className={styles.close}>
                        {<Close onClick={onClose} />}
                    </div>
                <div className={classNames(styles.body)}>
                    <div className={styles.iconSuccesMail}>
                    {<Icon  />}
                    </div>
                    <p className={styles.msgSuccesMail}>Ваша заявка успешно оформлена! <br/> Ожидайте подтверждения бронирования!</p>
                </div>
            </div>
        </div>,
        container
    );
}

export default SuccesReserve


