import React, { FC, useEffect, useState } from 'react'
import Layout from '../../complex/Layout/Layout'
import { ReactComponent as Close } from '../../../assets/icon/close.svg'
import styles from './Blog.module.scss'
import img from '../../../assets/image/blogImg.jpg'
import img2 from '../../../assets/image/blogImg2.jpg'
import Section from '../../simple/Section/Section'
import { RouteSectionType } from '../../../Types/Types'
import { BlogContext } from './BlogContext'
import BlogCard from './parts/BlogCard'
import BlogItem from './parts/BlogItem'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../../../utilits/firebaseConfig'
import { getStorage, ref } from "firebase/storage";
type Props = {}


export type BlogType = {
    id: string
    title: string
    listAdvanced: string[]
    img: string
    advancedImg: string
    advancedTitle: string
    description: string

}
const Blog = (props: Props) => {
    const [blogItem, setBlogItem] = useState<string | undefined>()
    const [blogItemObj, setBlogItemObj] = useState<BlogType | undefined>()

    const [blog, setBlog] = useState<BlogType[]>([])


    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {
        if (blogItem) {
            const itemFind = blog.find(item => item.id === blogItem)
            setBlogItemObj(itemFind)
        } else {
            setBlogItemObj(undefined)
        }
    }, [blogItem])

    const getBlog = async () => {
        setLoading(false)
        const blogColl = collection(db, 'blog')
        const data = await getDocs(blogColl)
        setBlog(data.docs.map((doc: any) => ({ ...doc.data(), id: doc.id })))
        setLoading(true)
    }
    useEffect(() => {
        getBlog()
    }, [])
    const SectMain: FC = (): JSX.Element => {
        return (
            <Section label="Последние записи:" sections={blog.map(item => ({
                label: item.title,
                rout: 'blog'
            }))} />
        )
    }

    return (
        <BlogContext.Provider value={[blog, setBlog, blogItem, setBlogItem]}>
            <Layout className={styles.layoutBlog} mainSection={<SectMain />}>
                <section className={styles.blog}>
                    {blogItemObj &&
                        <BlogItem data={blogItemObj} />
                    }
                    <div className={styles.wrapper}>
                        {loading && blog.map((item, i) => <BlogCard key={i} data={item} />)}
                        {/* <div className={styles.wrapperCard}>
                            <div className={styles.cardImage} >
                                <img src={img} className={styles.image} />
                                <h4 className={styles.cardImageTitle}>Вместительность плота</h4>
                            </div>
                            <div className={styles.cardDescription}>
                                <p className={styles.cardDescriptionParagraph}>На плоту есть всё для безопасных путешествий на воде. Наш капитан наслаждается безопасностью поэтому запрещает:
                                    - приносить с собой оружие, агрессивные мысли, плохое настроение, истерики
                                </p>
                                <span onClick={() => setisShowAdvanced(true)} className={styles.cardDescriptionReadMore}>Читать далее</span>
                            </div>
                        </div> */}
                    </div>
                </section>
            </Layout>
        </BlogContext.Provider>
    )
}

export default Blog


const context = [
    {

    }
] 